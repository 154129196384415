$page-background: #212121;
$page-color: #aaa;
$page-light-color: #DDD;
$page-darkcolor: #555;
$card-page-background: #757575;
$cart-header-color: #aaa;
$card-header-background: #bdbdbd;
$card-background: #eee;

$link-color: #000;
$link-border-radius: 12px;
$link-height: 56px;

$blue-background: #029BD5;
$blue-border: #01678E;
$blue-hover: #018EC4;

$darkblue-background: hsl(209, 87%, 21%);
$darkblue-border: hsl(209, 87%, 31%);
$darkblue-hover: hsl(209, 87%, 25%);

$darkcyan-background: hsl(191, 67%, 14%);
$darkcyan-border: hsl(191, 67%, 24%);
$darkcyan-hover: hsl(191, 67%, 16%);

$darkgreen-background: hsl(100, 61%, 19%);
$darkgreen-border: hsl(100, 61%, 29%);
$darkgreen-hover: hsl(100, 61%, 21%);

$darkred-background: hsl(0, 100%, 31%);
$darkred-border: hsl(0, 100%, 20%);
$darkred-hover: hsl(0, 100%, 29%);

$darkviolet-background: hsl(328, 63%, 18%);
$darkviolet-border: hsl(328, 63%, 28%);
$darkviolet-hover: hsl(328, 63%, 21%);

$gray-background: #BBB;
$gray-border: #555;
$gray-hover: #AAA;

$green-background: #5D9E04;
$green-border: #3E6902;
$green-hover: #559103;

$lightblue-background: hsl(191, 26%, 57%);
$lightblue-border: hsl(191, 26%, 27%);
$lightblue-hover: hsl(191, 26%, 47%);

$lightorange-background: hsl(45, 87%, 57%);
$lightorange-border: hsl(45, 87%, 37%);
$lightorange-hover: hsl(45, 87%, 50%);

$orange-background: #F19607;
$orange-border: #A06404;
$orange-hover: #DF8B06;

$pink-background: hsl(331, 36%, 74%);
$pink-border: hsl(331, 36%, 54%);
$pink-hover: hsl(331, 36%, 68%);

$ligthz-background: hsl(338, 68%, 70%);
$ligthz-border: hsl(338, 42%, 49%);
$ligthz-hover: hsl(338, 71%, 66%);

$red-background: #E64111;
$red-border: #992B0B;
$red-hover: #D73C0F;

$violet-background: hsl(257, 36%, 58%);
$violet-border: hsl(257, 36%, 38%);
$violet-hover: hsl(257, 36%, 54%);


$scheduler-item-background: #ddd;
$scheduler-item-border: #555;
$scheduler-item-active-color: rgb(44, 123, 214);
$scheduler-item-active-background: #fff;