@import "_normalize";
@import "_variables";
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap&subset=latin-ext');
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    align-items: center;
    background-color: $card-page-background;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;
    align-items: flex-start;
    min-width: 270px;
    scroll-padding-top: 62px;
    min-height: 100vh;
    &.page-menu {
        background-color: $page-background;
    }
}

.app {
    &__refresh {
        align-items: center;
        background-color: #ffffff00;
        border: none;
        color: $page-light-color;
        cursor: pointer;
        display: flex;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        justify-content: center;
        line-height: 16px;
        text-align: center;
        & img {
            margin-right: .5rem;
        }
    }
}

.board {
    align-self: center;
    margin: 32px;
    &__title {
        color: $page-color;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        text-align: center;
    }
    &__footer {
        color: $page-darkcolor;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
    }
}

.gallery {
    overflow: hidden;
    margin-top: 58px;
    &>img {
        width: 100vw;
    }
}

.navigation {
    &__desc {
        display: block;
        font-size: 1rem;
        // text-transform: uppercase;
        line-height: 1.3rem;
        margin-top: .5rem;
        // margin-bottom: .5rem;
        letter-spacing: .05rem;
    }
    &__list {
        display: flex;
        flex-direction: column;
        flex-grow: 32px;
        padding: 0;
    }
    &__item {
        border-radius: $link-border-radius;
        display: block;
        margin: 0;
        min-width: 300px;
        @media(min-width: 600px) {
            min-width: 500px;
        }
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    &__link {
        background-color: $gray-background;
        border-bottom: 4px solid $gray-border;
        border-radius: $link-border-radius;
        color: $link-color;
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        // line-height: $link-height;
        padding: 0.9rem 0;
        text-align: center;
        text-decoration: none;
        transition: background .3s ease-in-out, transform .1s ease-in-out;
        &:hover {
            background-color: $gray-hover;
            transform: scale(1.01);
        }
        &--blue {
            background-color: $blue-background;
            border-bottom: 4px solid $blue-border;
            &:hover {
                background-color: $blue-hover;
            }
        }
        &--darkblue {
            background-color: $darkblue-background;
            border-bottom: 4px solid $darkblue-border;
            color: #bbb;
            &:hover {
                background-color: $darkblue-hover;
            }
        }
        &--darkcyan {
            background-color: $darkcyan-background;
            border-bottom: 4px solid $darkcyan-border;
            color: #bbb;
            &:hover {
                background-color: $darkcyan-hover;
            }
        }
        &--darkgreen {
            background-color: $darkgreen-background;
            border-bottom: 4px solid $darkgreen-border;
            color: #bbb;
            &:hover {
                background-color: $darkgreen-hover;
            }
        }
        &--darkred {
            background-color: $darkred-background;
            border-bottom: 4px solid $darkred-border;
            color: #bbb;
            &:hover {
                background-color: $darkred-hover;
            }
        }
        &--darkviolet {
            background-color: $darkviolet-background;
            border-bottom: 4px solid $darkviolet-border;
            color: #bbb;
            &:hover {
                background-color: $darkviolet-hover;
            }
        }
        &--green {
            background-color: $green-background;
            border-bottom: 4px solid $green-border;
            &:hover {
                background-color: $green-hover;
            }
        }
        &--lightblue {
            background-color: $lightblue-background;
            border-bottom: 4px solid $lightblue-border;
            &:hover {
                background-color: $lightblue-hover;
            }
        }
        &--lightorange {
            background-color: $lightorange-background;
            border-bottom: 4px solid $lightorange-border;
            &:hover {
                background-color: $lightorange-hover;
            }
        }
        &--orange {
            background-color: $orange-background;
            border-bottom: 4px solid $orange-border;
            &:hover {
                background-color: $orange-hover;
            }
        }
        &--pink {
            background-color: $pink-background;
            border-bottom: 4px solid $pink-border;
            &:hover {
                background-color: $pink-hover;
            }
        }
        &--ligthz {
            background-color: $ligthz-background;
            border-bottom: 4px solid $ligthz-border;
            &:hover {
                background-color: $ligthz-hover;
            }
        }
        &--red {
            background-color: $red-background;
            border-bottom: 4px solid $red-border;
            &:hover {
                background-color: $red-hover;
            }
        }
        &--violet {
            background-color: $violet-background;
            border-bottom: 4px solid $violet-border;
            &:hover {
                background-color: $violet-hover;
            }
        }
        &--withPadding {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

.toolbar {
    align-items: center;
    background-color: $gray-background;
    box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.6);
    display: flex;
    flex-direction: row;
    font-size: 12px;
    line-height: 18px;
    justify-content: space-around;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    &--blue {
        background-color: $blue-background;
        border-bottom-color: $blue-border;
    }
    &--gray {
        background-color: $gray-background;
        border-bottom-color: $gray-border;
    }
    &--green {
        background-color: $green-background;
        border-bottom-color: $green-border;
    }
    &--lightblue {
        background-color: $lightblue-background;
        border-bottom-color: $lightblue-border;
    }
    &--orange {
        background-color: $orange-background;
        border-bottom-color: $orange-border;
    }
    &--pink {
        background-color: $pink-background;
        border-bottom-color: $pink-border;
    }
    &--red {
        background-color: $red-background;
        border-bottom-color: $red-border;
    }
    &--violet {
        background-color: $violet-background;
        border-bottom-color: $violet-border;
    }
    &__button {
        background: none;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        border-radius: 100px;
        display: block;
        height: 36px;
        padding: 8px 8px;
        width: 36px;
        &--back {
            background-image: url('../assets/icons/icon-arrow_back.svg');
        }
        &--search {
            background-image: url('../assets/icons/icon-search.svg');
        }
        &--today {
            background-image: url('../assets/icons/icon-calendar_today.svg');
        }
        &:first-child {
            margin-left: 8px;
        }
        &:last-child {
            margin-right: 8px;
        }
    }
    &__title {
        flex: 1;
        text-align: center;
    }
}

.scheduler {
    width: 100%;
    margin-top: 50px;
    &__header {
        align-items: center;
        background-color: $gray-background;
        border-bottom: 4px solid $gray-border;
        display: flex;
        flex-direction: row;
        font-size: 12px;
        line-height: 18px;
        justify-content: space-around;
        &--cart {
            background-color: $orange-background;
            border-bottom-color: $orange-border;
        }
    }
    &__link {
        background: red;
        border: none;
        border-radius: 100px;
        display: block;
        height: 36px;
        padding: 8px 8px;
        width: 36px;
        &:first-child {
            margin-left: 8px;
        }
        &:last-child {
            margin-right: 8px;
        }
        &--cart {
            color: $orange-background;
        }
    }
    &__title {
        flex: 1;
        text-align: center;
    }
}

.dayList {
    display: flex;
    flex-wrap: wrap;
    margin: 16px auto;
    max-width: 1250px;
    @media (max-width: 1250px) {
        max-width: 1000px;
    }
    @media (max-width:1000px) {
        max-width: 750px;
    }
    @media (max-width:750px) {
        max-width: 500px;
    }
    @media (max-width:500px) {
        max-width: 250px;
    }
    @media (max-width:414px) {
        max-width: inherit;
        margin: 4px 0;
    }
}

.day__firstToday::before {
    content: " ";
    display: block;
    height: 50px;
    margin-top: -50px;
}

.day {
    background-color: $card-background;
    border-radius: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    width: 100%;
    overflow: hidden;
    @media (min-width: 414px) {
        border-radius: 2px;
        margin: 8px;
        width: 234px;
        transition: transform 0.1s ease-in-out;
        &:hover {
            transform: scale(1.02);
        }
    }
    &__group {
        align-items: center;
        color: $orange-hover;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-weight: 700;
        justify-content: center;
        letter-spacing: 1.5px;
        padding: 16px;
        text-transform: uppercase;
        margin: 0;
        & span {
            font-size: 5rem;
            font-weight: 400;
        }
    }
    &__table {
        display: block;
        font-size: 14px;
        margin: 4px;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
        & tr {
            &:not(:last-child) {
                border-bottom: 1px solid $gray-hover;
            }
        }
        & td {
            padding: 2px 4px;
            text-align: center;
            &:first-child {
                width: 1%;
            }
            &:last-child {
                white-space: nowrap;
            }
        }
    }
}

.dayHeader {
    background-color: $card-header-background;
    border-bottom: 4px solid $gray-border;
    padding: 16px;
    position: relative;
    &__today--cart {
        background-color: $orange-background;
    }
    &__date {
        margin: 8px 0 10px 0;
        font-size: 24px;
        line-height: 28px;
    }
    &__icon {
        height: 24px;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    &__place {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        & img {
            height: 16px;
            margin: 0 8px -2px 0;
        }
    }
    &__weekday {
        font-size: 14px;
        letter-spacing: 1.5px;
        line-height: 16px;
        margin: 0;
        text-transform: uppercase;
    }
}

.zoomLinks {
    background-color: $card-background;
    overflow: hidden;
    margin-bottom: -58px;
    margin-top: 50px;
    &__item {
        border-radius: $link-border-radius;
        display: inline-block;
        margin: .5rem;
        min-width: 130px;
        @media(min-width: 600px) {
            min-width: 150px;
        }
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    &__link {
        background-color: $gray-background;
        border-bottom: 4px solid $gray-border;
        border-radius: $link-border-radius;
        color: $link-color;
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.3rem;
        line-height: 2.5rem;
        text-align: center;
        text-decoration: none;
        transition: background .3s ease-in-out, transform .1s ease-in-out;
        &--green {
            background-color: $green-background;
            border-bottom: 4px solid $green-border;
            &:hover {
                background-color: $green-hover;
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: .5rem;
    }
}

.links {
    background-color: $card-background;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-bottom: -58px;
    margin-top: 50px;
    width: 100vw;
    &__item {
        border-radius: $link-border-radius;
        display: inline-block;
        margin: .5rem;
        min-width: 130px;
        @media(min-width: 600px) {
            min-width: 150px;
        }
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: .5rem;
    }
}